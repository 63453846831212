//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBTt7Pe_H5_rbfpdjp6m2a9LNwaZRNDBW0",
  authDomain: "pigeon-1c6a9.firebaseapp.com",
  projectId: "pigeon-1c6a9",
  storageBucket: "pigeon-1c6a9.appspot.com",
  messagingSenderId: "818452934681",
  appId: "1:818452934681:web:46b6b356b51a8e071a3a88",
  measurementId: "G-S7BRCZCM0Z",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
